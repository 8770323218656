import { CoinGeckoClient } from 'coingecko-api-v3';

const useGetFreePrice = async () => {  
  const client =  new CoinGeckoClient({
    timeout: 10000,
    autoRetry: true,
  });
  // try {    
    const d = await client.simplePrice({
      vs_currencies: 'usd',
      ids: 'freedom-coin',
      include_market_cap: false,
      include_24hr_vol: false,
      include_24hr_change: false,
      include_last_updated_at: false 
    })
    
    if (d['freedom-coin'].usd != null) {
      document.title = `Freefinance - $${d['freedom-coin'].usd}`;
    }
    
    return d['freedom-coin'].usd;
    /*
  } catch {
    return d['freedom-coin'].usd;
  }
  */
}

export default useGetFreePrice


const TestNet = false;
let CoreObject = {
    RouterV2: '-',
    chainID: '97',
    network: false
}
if (TestNet) {
    // FOR TEST NET
    CoreObject = {
        RouterV2: '0x671E8389c7eD4D66252399250F06EcAF2192c3C2',
        chainID: '97',
        network: TestNet
    }
} else {
    // FOR MAIN NET
    CoreObject = {
        RouterV2: '0x0163b81594e45f9a4889431417d4840301Ea359d', // for pancakeswap: 0x10ED43C718714eb63d5aA57B78B54704E256024E
        chainID: '56',
        network: TestNet
    }
}

/*
  [ChainId.MAINNET]: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  [ChainId.TESTNET]: '0x301907b5835a2d723Fe3e9E8C5Bc5375d5c1236A'
*/
export const ZCore = CoreObject
export const multicallV1 = {
    MAINNET: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    TESTNET: '0xfe503FC060E20759638541BFDf34CF6bD887ef73'
}

// UPDATE
export const lottary = {
    MAINNET: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    TESTNET: '0xfe503FC060E20759638541BFDf34CF6bD887ef73'
}
export const xmint = {
    MAINNET: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
    TESTNET: '0xfe503FC060E20759638541BFDf34CF6bD887ef73'
}
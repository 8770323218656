import React from 'react'
import styled from 'styled-components'
import { LinkExternal, Flex } from '@pancakefork/uikit'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <Flex flexDirection={['column', 'column', 'row']} alignItems="center">
        <LinkExternal
          href="https://www.binance.org/en/bridge?utm_source=PancakeSwap"
          ml={[0, 0, '40px']}
          mt={['20px', '20px', 0]}
          mb={['8px', '8px', 0]}
        >
          Convert ERC-20 to BEP-20
        </LinkExternal>
      </Flex>
    </Wrapper>
  )
}

export default Footer


import { multicallV1 } from 'config/ZCore'
import { ChainId } from '@pancakefork/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: multicallV1.MAINNET,
  [ChainId.TESTNET]: multicallV1.TESTNET
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }

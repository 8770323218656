import React from 'react'
import styled from 'styled-components'
import { Spinner } from '@pancakefork/uikit'
import Page from '../Layout/Page'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const loading = <div style={{color: "#fff"}}>Loading</div>

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      {loading}
    </Wrapper>
  )
}

export default PageLoader
